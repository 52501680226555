import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../../configuration/setup/hooks';
import { getUserProfileId } from '../../../../configuration/login/loginSlice';
import { useFetchUserQuery } from '../../services/usersApi';
import { CustomSpinner } from '../../common';
import { MfaSelection } from './MfaSelection';

export const LoginAndSecurity = () => {
    const userProfileId = useAppSelector(getUserProfileId);
    const { data: user, isLoading } = useFetchUserQuery(userProfileId!);

    const preferredLogin = user?.email ? user.email : user?.phoneNumber;

    return isLoading ? (
        <CustomSpinner />
    ) : (
        <form className={'form-horizontal'}>
            <div className={'display-flex flex-column gap-5'}>
                <div className={'form-group'}>
                    <label className={'control-label'} htmlFor={'userName-input'}>
                        <FormattedMessage id={'intl-msg:settingsDialog.tab.personalData.label.userName'} />
                    </label>
                    <input
                        type={'text'}
                        id={'userName-input'}
                        className={'form-control'}
                        autoComplete={'username'}
                        readOnly
                        value={preferredLogin || ''}
                    />
                    {/*<span className="position-absolute top-1 bottom-1 right-1 rounded bg-lightest">*/}
                    {/*    <a*/}
                    {/*        className="btn btn-link text-size-12"*/}
                    {/*        href="https://rio.cloud/en/contact/form?opener=Change%20Email%20Address"*/}
                    {/*        target="_blank"*/}
                    {/*    >*/}
                    {/*        <span>Change Email</span>*/}
                    {/*    </a>*/}
                    {/*</span>*/}
                </div>

                <div className={'form-group'}>
                    <label className={'control-label'} htmlFor={'password-input'}>
                        <FormattedMessage id={'intl-msg:settingsDialog.tab.personalData.label.password'} />
                    </label>
                    <div className={'position-relative'}>
                        <input
                            type={'password'}
                            id={'password-input'}
                            autoComplete={'new-password'}
                            className={'form-control'}
                            value={'xxxxxxxx'}
                            readOnly
                        />
                        <span className={'position-absolute top-1 bottom-1 right-1 rounded bg-lightest'}>
                            <a
                                className={'btn btn-link'}
                                href={'https://auth.iam.rio.cloud/forgot-password'}
                                target={'_blank'}
                                rel={'noopener noreferrer'}
                            >
                                <FormattedMessage id={'intl-msg:settingsDialog.tab.personalData.label.passwordReset'} />
                            </a>
                        </span>
                    </div>
                </div>

                <MfaSelection />
            </div>
        </form>
    );
};
