import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useActivateOtpMutation, useDeactivateOtpMutation, useFetchUserQuery } from '../../services/usersApi';
import { ErrorBanner } from '../../common/ErrorBanner';
import { useAppSelector } from '../../../../configuration/setup/hooks';
import { getUserProfileId } from '../../../../configuration/login/loginSlice';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { ErrorStatusCodes } from '../../common/ErrorStatusCodes';
import RadioButton from '@rio-cloud/rio-uikit/RadioButton';
import { extractLanguageForGlossar } from '../../../../configuration/lang/lang';
import { getIsDemoFleet } from '../../../../configuration/tokenHandling/tokenHandlingSlice';
import { isMaintenanceMode } from '../../appSlice';

const getSelectedClass = (isSelected: boolean) =>
    isSelected ? 'border-color-highlight bg-highlight-decent pointer-events-none z-index-1' : 'bg-decent';

export const MfaSelection = () => {
    const userProfileId = useAppSelector(getUserProfileId);
    const isInMaintenanceMode = useAppSelector(isMaintenanceMode);
    const isDemoFleet = useAppSelector(getIsDemoFleet);

    const { data: user, isLoading } = useFetchUserQuery(userProfileId!);

    const [lastOptionClicked, setLastOptionClicked] = useState<MfaOption | undefined>();

    const [
        activateOtp,
        { isLoading: isUpdatingActivate, isError: isErrorActivate, isSuccess: isSuccessActivate, error: activateError },
    ] = useActivateOtpMutation();

    const [
        deactivateOtp,
        {
            isLoading: isUpdatingDeactivate,
            isError: isErrorDeactivate,
            isSuccess: isSuccessDeactivate,
            error: deactivateError,
        },
    ] = useDeactivateOtpMutation();

    const intl = useIntl();
    const glossarLanguage = extractLanguageForGlossar(intl.locale);

    const userAdditionalAuthFactors = user?.additionalAuthenticationFactors;

    const isUpdating = isUpdatingActivate || isUpdatingDeactivate;
    const isError = isErrorActivate || isErrorDeactivate;

    // Setup two separate useEffects in order to prevent double notifications
    // which otherwise happens after you e.g. activate, deactivate and then activate again
    useEffect(() => {
        if (isSuccessDeactivate && (!isSuccessActivate || isSuccessActivate)) {
            notifyUpdateSuccess(intl);
        }
    }, [isSuccessDeactivate]);
    useEffect(() => {
        if (isSuccessActivate && (!isSuccessDeactivate || isSuccessDeactivate)) {
            notifyUpdateSuccess(intl);
        }
    }, [isSuccessActivate]);

    const handleClick = (e: ChangeEvent | FormEvent, option: MfaOption) => {
        e.preventDefault();

        if (user && !isLoading) {
            setLastOptionClicked(option);

            switch (option) {
                case 'disabled':
                    deactivateOtp(user.id);
                    break;
                case 'otp':
                    activateOtp(user.id);
                    break;
                // case 'totp':
                //     console.log(option);
                //     alert('Under construction.');
            }
        }
    };

    return isLoading ? null : (
        <div className="form-group">
            <label className="control-label display-flex justify-content-between margin-top-2">
                <FormattedMessage
                    id={'intl-msg:settingsDialog.tab.loginAndSecurity.mfa.label'}
                    defaultMessage={'Two-factor authentication'}
                />
                <a
                    className={'link white-space-nowrap margin-left-10'}
                    href={`https://rio.cloud/${glossarLanguage}/glossar/two-factor-authentication?noCookie=1`}
                    target={'_blank'}
                    rel={'noreferrer'}
                    tabIndex={-1}
                    style={{ float: 'right' }}
                >
                    <FormattedMessage id={'intl-msg:settingsDialog.tab.loginAndSecurity.mfa.help'} />
                </a>
            </label>
            <RadioButton
                name="stackedRadioBoxes"
                custom
                className={`padding-15 rounded-top border ${getSelectedClass(userAdditionalAuthFactors === undefined || userAdditionalAuthFactors.length === 0)}`}
                onChange={(e) => handleClick(e, 'disabled')}
                checked={userAdditionalAuthFactors === undefined || userAdditionalAuthFactors.length === 0}
                disabled={isInMaintenanceMode || isDemoFleet}
            >
                <div className="radio-text-wrapper display-flex">
                    <span className="radio-text margin-top-2" />
                    <div className="display-flex justify-space-between align-items-center">
                        <div className="margin-left-5 margin-right-5">
                            <div className="text-medium display-flex align-items-center">
                                <FormattedMessage
                                    id="intl-msg:settingsDialog.tab.loginAndSecurity.mfaOptions.disabled"
                                    defaultMessage="Two-factor disabled"
                                />
                                <div
                                    className="btn btn-xs btn-muted btn-icon-only btn-loading margin-0"
                                    style={{
                                        visibility:
                                            lastOptionClicked === 'disabled' && isUpdating ? 'visible' : 'hidden',
                                    }}
                                />
                            </div>
                            <div className="text-color-dark ellipsis" style={{ maxWidth: '340px' }}>
                                <FormattedMessage
                                    id="intl-msg:settingsDialog.tab.loginAndSecurity.mfaOptions.disabled.description"
                                    defaultMessage="Login only requires user name and password"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </RadioButton>

            <RadioButton
                name="stackedRadioBoxes"
                custom
                className={`padding-15 margin-top--1 rounded-bottom border ${getSelectedClass(userAdditionalAuthFactors?.includes('otp') === true)}`}
                onChange={(e) => handleClick(e, 'otp')}
                checked={userAdditionalAuthFactors?.includes('otp')}
                disabled={isInMaintenanceMode || isDemoFleet}
            >
                <div className="radio-text-wrapper display-flex">
                    <span className="radio-text margin-top-2" />
                    <div className="display-flex justify-space-between align-items-center">
                        <div className="margin-left-5 margin-right-5">
                            <div className="text-medium display-flex align-items-center">
                                <FormattedMessage
                                    id="intl-msg:settingsDialog.tab.loginAndSecurity.mfaOptions.otp"
                                    defaultMessage="Email/SMS verification code"
                                />
                                <div
                                    className="btn btn-xs btn-muted btn-icon-only btn-loading"
                                    style={{
                                        visibility: lastOptionClicked === 'otp' && isUpdating ? 'visible' : 'hidden',
                                    }}
                                />
                            </div>
                            <div className="text-color-dark ellipsis" style={{ maxWidth: '340px' }}>
                                <FormattedMessage
                                    id="intl-msg:settingsDialog.tab.loginAndSecurity.mfaOptions.otp.description"
                                    defaultMessage="At log in, you will receive a code sent to your email or phone number"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </RadioButton>
            {isError && (
                <ErrorBanner>
                    <strong className="text-size-16 margin-right-5">Error!</strong>
                    <ErrorStatusCodes errors={[activateError, deactivateError]} />
                    <div>
                        <FormattedMessage
                            id={'intl-msg:settingsDialog.tab.loginAndSecurity.mfa.error'}
                            defaultMessage={'Could not change MFA settings.'}
                        />
                    </div>
                </ErrorBanner>
            )}
        </div>
    );
};

const mfaOptions = [
    'disabled',
    'otp',
    // 'totp',
] as const;
type MfaOption = (typeof mfaOptions)[number];

const notifyUpdateSuccess = (intl: IntlShape) => {
    Notification.success(
        intl.formatMessage({
            id: 'intl-msg:settingsDialog.tab.loginAndSecurity.mfa.success',
            defaultMessage: 'Settings updated',
        })
    );
};
