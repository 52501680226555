import { useAppSelector } from '../../../../configuration/setup/hooks';
import { getUserProfileId } from '../../../../configuration/login/loginSlice';
import { useFetchAccountsQuery, useFetchUserQuery } from '../../services/usersApi';
import { useEffect, useState } from 'react';
import { Account } from '../../services/types';
import { useIntl } from 'react-intl';

const displayAccountId =
    localStorage.getItem('displayAccountId')?.toLowerCase() === 'true' ||
    localStorage.getItem('displayAccountId')?.toLowerCase() === 'yes' ||
    localStorage.getItem('displayAccountId')?.toLowerCase() === 'y' ||
    localStorage.getItem('displayAccountId') === '1';

export const UserInNavbar = () => {
    const [currentAccount, setCurrentAccount] = useState<Account | undefined>(undefined);

    const userProfileId = useAppSelector(getUserProfileId);

    const { data: user } = useFetchUserQuery(userProfileId!);
    const { data: accounts } = useFetchAccountsQuery(userProfileId!);

    if (user === undefined) {
        return null;
    }

    useEffect(() => {
        const candidate = accounts?.find((acc: { active: boolean }) => acc.active);
        if (candidate) {
            setCurrentAccount(candidate);
        }
    }, [accounts]);

    const preferredLogin = user.email ? user.email : user.phoneNumber || '';
    return (
        <div className="margin-bottom-10 padding-y-10">
            <div className="display-flex align-items-center flex-column gap-10 line-height-125rel text-color-dark text-size-14">
                <div
                    className="ellipsis-1 max-width-250 text-size-16 text-bold"
                    title={`${user.firstName} ${user.lastName}`}
                >
                    {`${user.firstName} ${user.lastName}`}
                </div>
                <div className="ellipsis-1 max-width-250" title={preferredLogin}>
                    {preferredLogin}
                </div>
                {currentAccount && <CurrentAccount currentAccount={currentAccount} />}
                {currentAccount && displayAccountId && (
                    <div className="display-flex flex-wrap align-items-center justify-content-center">
                        <span className="badge badge-muted" style={{ cursor: 'default' }} title={'Account ID'}>
                            {abbreviateString(currentAccount.id)}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

const CurrentAccount = ({ currentAccount }: { currentAccount: Account }) => {
    const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        copyToClipboard(currentAccount);
    };

    const intl = useIntl();

    return (
        <div className="display-flex flex-wrap align-items-center justify-content-center">
            <div
                className="display-flex gap-5 badge badge-muted"
                style={{ cursor: 'pointer' }}
                onClick={handleOnClick}
                title={intl.formatMessage({ id: 'intl-msg:settingsDialog.accountInfo.tooltip' })}
            >
                <span className={'margin-left-3'}>{abbreviateString(currentAccount.name)}</span>
                <span className={'rioglyph rioglyph-clipboard margin-right-3'} />
            </div>
        </div>
    );
};

const copyToClipboard = (account: Account) => {
    try {
        // Fallback: Create a temporary textarea element
        const textArea = document.createElement('textarea');
        textArea.value = JSON.stringify({ accountName: account?.name, accountId: account?.id });
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
    } catch (err) {
        console.error('Failed to copy text: ', err);
    }
};

const abbreviateString = (input: string) => {
    const maxLength = 56;

    // Check if the string needs to be abbreviated
    if (input.length > maxLength) {
        // Find the last space before the 60 character limit
        let lastSpace = input.substring(0, maxLength).lastIndexOf(' ');

        // If there is no space, or the space is very early in the string, we truncate at maxLength
        if (lastSpace === -1 || lastSpace < 30) {
            // 30 is arbitrary, adjust based on your needs
            return input.substring(0, maxLength) + ' ...';
        }

        // Return the string up to the last space and append ' ...'
        return input.substring(0, lastSpace) + ' ...';
    }

    // Return the original string if it's shorter than maxLength
    return input;
};
