import packageJson from '../package.json';

export interface ConfigState {
    backend: {
        AUTHENTICATION_SERVICE: string | undefined;
        USER_ADMIN_SERVICE: string | undefined;
        POLL_INTERVAL: string | undefined;
        LOGOUT_URI: string | undefined;
    };
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: Array<string>;
        mockAuthorization: boolean;
        mockTenant: string | undefined;
        mockLocale: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
    serviceVersion: string;
    serviceEnvironment: string;
    logoutUri: string | undefined;
    sentryToken: string;
    enableMockServer: boolean;
    demoFleetAccountId: string;
}

const asBool = (value: string | undefined): boolean => value === 'true';

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        USER_ADMIN_SERVICE: import.meta.env.VITE_USER_ADMIN_SERVICE,
        POLL_INTERVAL: import.meta.env.VITE_POLL_INTERVAL,
        LOGOUT_URI: import.meta.env.VITE_LOGOUT_URI,
    },
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: 'a5c64704-a257-43c7-bd82-966854f7c1d0',
        oauthScope: [' '],
        mockAuthorization: import.meta.env.DEV,
        mockTenant: import.meta.env.VITE_LOGIN_MOCK_TENANT,
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    serviceVersion: packageJson.version,
    serviceEnvironment: import.meta.env.MODE,
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    sentryToken: 'https://7281cead87d04c1e9abee5b677dc13a1@o117480.ingest.sentry.io/6203647',
    enableMockServer: import.meta.env.DEV,
    demoFleetAccountId: '47bbd84b-b46d-4bdc-a9d7-eb36b70358ec', // used to disable changing user details in the demo fleet
};
