export const triggerPageReload = () => {
    if (import.meta.env.PROD) {
        // TODO: Find a better way than hard page reload.
        //  This could be resolved by updating the browser_state cookie,
        //  which has to be done in the auth-server and then published via Set-Cookie header.
        window.parent.location.href = document.referrer ? document.referrer : 'https://home.rio.cloud';
    } else {
        window.location.reload();
    }
};
