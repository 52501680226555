import 'iframe-resizer/js/iframeResizer.contentWindow';

import { Provider } from 'react-redux';
import { config } from './config';
import { main } from './configuration';
import { store } from './configuration/setup/store';
import { ErrorBoundary } from './features/common/ErrorBoundary';
import App from './features/App';
import { createRoot } from 'react-dom/client';
import handleLoginRedirect from './configuration/login/redirect';

const renderApplication = () => {
    const container = document.getElementById('root') as HTMLElement;
    const root = createRoot(container);

    root.render(
        <ErrorBoundary>
            <Provider store={store}>
                <App />
            </Provider>
        </ErrorBoundary>
    );
};

const isDev = import.meta.env.DEV;
const isProd = import.meta.env.PROD;
const isProdPreview = import.meta.env.VITE_PRODUCTION_PREVIEW;

if ((isDev && config.enableMockServer) || isProdPreview) {
    import('../mocks/browser').then(({ startWorker }) => startWorker()).then(() => main(renderApplication));
}

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else if (isProd && !isProdPreview) {
    void main(renderApplication);
}
